/* autoprefixer grid: autoplace */

$basf-blue: #21a0d2;
$basf-green: #65ac1e;
$basf-orange: #f39500;
$basf-red: #c50022;

$tablet-width: 768px;
$tablet-large-width: 860px;
$desktop-medium-width: 1200px;
$desktop-large-width: 1600px;
$container-width: 1600px;

$font-body: 'Helvetica Neue BASF';

@mixin desktop-large-below {
    @media (max-width: #{$desktop-large-width - 1px}) {
      @content;
    }
}
@mixin desktop-below {
    @media (max-width: #{$desktop-medium-width - 1px}) {
      @content;
    }
}
@mixin tablet-large-below {
    @media (max-width: #{$tablet-large-width - 1px}) {
      @content;
    }
}
@mixin tablet-below {
    @media (max-width: #{$tablet-width - 1px}) {
      @content;
    }
}

$element-card-width: $container-width / 12;
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
  //  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
    font-family: $font-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
*{
    box-sizing: border-box;
}
strong{
    font-weight:bold;
}

.container {
    width: $container-width;
    margin: 0 auto;
    max-width:100%;
    padding:1rem 0;

    @include desktop-large-below {
        padding: 40px;
    }
}

.country-toggle {
    display: flex;
    justify-content: space-between;
    max-width: 130px;
    span {
        cursor: pointer;
    }
}

.header {
    ul {
        float: right;
        margin: 40px 0 60px;
    }

    li {
        display: inline-block;
        padding-left: 20px;
        @include desktop-below {
            margin-bottom: 1.5rem;
        }

        span.benefits {
            border-bottom: 6px solid $basf-blue;
            padding-bottom: 3px;
        }

        span.learning-development {
            border-bottom: 6px solid $basf-green;
            padding-bottom: 3px;
        }

        span.work-environment {
            border-bottom: 6px solid $basf-orange;
            padding-bottom: 3px;
        }

        span.compensation {
            border-bottom: 6px solid $basf-red;
            padding-bottom: 3px;
        }
    }
}

.element-table {
    display:flex;
    flex-wrap:wrap;
    // display:grid;
    // grid-template-columns: repeat(11, minmax(0, 1fr));
    // grid-gap: .8rem;
    // padding-bottom: 100px;
    width: 100%;
    h3 {
        color: #fff;
        font-weight: bold;
    }

    &.canada {
        .element-card {
            width: 12.5%;
        }

        .table-header {
            width: 62.4%;
        }
    }
}

.table-header {
    width: 54%;
    //width: $element-card-width * 6;
    // grid-column-start: 3;
    // grid-column-end: 9;
    padding: 12px;
    // margin: 0 15px;
  
    svg {
        width: 60%;
        @include desktop-below {
            max-height: 100px;
        }
    }
   
}

.element-card {

    width: 9%;
    padding: .5%;

    .element-card__content-wrapper{
        transition: all .2s ease-in-out;
        padding:2px 5px 8px;
        height:100%;
        position:relative;
        cursor:pointer;
        display:flex;
        flex-direction:column;

        // flex-wrap:wrap;
        // align-content:space-between;
        // align-items:space-between;
        justify-content: space-between;
        text-align:center;
        overflow:hidden;
        .star {
            height:20px;
        }
        &:hover:not(.blank) { 
            transform: scale(1.5); 
            webkit-box-shadow: -2px 4px 36px 5px rgba(0,0,0,0.9);
            -moz-box-shadow: -2px 4px 36px 5px rgba(0,0,0,0.9);
            box-shadow: -2px 4px 36px 5px rgba(0,0,0,0.9);
            z-index:123;
            .star{
                display:block;
                z-index: 124;
            }
        }
        &.blank {
            cursor: default;
        }
        &.top-row {
            width: 9%;
        }

        &.full {
            width: 9%;
        }

        &.benefits {
            background-color: $basf-blue;

            &.favourite {
                background-color: lighten($basf-blue, 20%);
            }
        }

        &.learning-development {
            background-color: $basf-green;

            &.favourite {
                background-color: lighten($basf-green, 20%);
            }
        }

        &.work-environment {
            background-color: $basf-orange;

            &.favourite {
                background-color: lighten($basf-orange, 20%);
            }
        }

        &.compensation {
            background-color: $basf-red;

            &.favourite {
                background-color: lighten($basf-red, 20%);
            }
        }
        &.favourite{
            .star{
                display:block;
                fill:yellow;
            }
        }
        .star{
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            max-width: 14px;
            fill:rgba(255,255,255,0.5);
            display:none;
        }

        img {
            width:100%;
        }
        svg:not(.star) {
            // max-width:100% !important; 
            // height:auto !important;
            width: auto;
            height: 100px;
            width: 100%;
            max-width:100px;
            color: #fff;
            fill: #fff;
            path,g{
            color: #fff!important;
            fill: #fff!important;
            }
            

            @include desktop-below{
                height: 60px;
            }
            
        }
        .content {
            width:100%;
        }
        h3 {
            font-size:0.9rem;
            font-weight: 400;
            @include desktop-large-below {
                font-size:0.8rem; //originally .7
            }
            @include desktop-below {
                font-size:0.65rem; //originally .5
            }
            // @include tablet-large-below {
            //     font-size:7px; 
            // }
        }
    }
}

.details {
    // grid-column-start: 1;
    // grid-column-end: 12;
    // float: left;
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-wrap:row;
}

.category-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #aaa;
    padding: 10px;
    background-color: #eee;
    margin: 2px;
    width:50%;
    svg {
        margin-right: 10px;
        width: 130px;
        @include tablet-below {
            margin-bottom:10px;
        }
    }

    div {
        text-align: left;

        span {
            display:block;
            font-weight: bold;
            padding-bottom:0.2rem;
        }
    }
    @include tablet-below {
        flex-direction:column;
    }
}

.element-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // flex: 1;
    text-align: left;
    border: 1px solid #aaa;
    padding: 10px;
    margin: 2px;
    width:50%;
    @include tablet-below {
        flex-direction:column;
    }
    
    div {
        text-align: left;

        > div:first-child {
            margin-right: 10px;
            @include tablet-below {
                margin-bottom:10px;
            }
        }
    }

    span {
            display:block;
            font-weight: bold;
            padding-bottom:0.2rem;
    }

    svg{
        width: 100px;
        height: 100px;
        color: #fff;
        fill: #fff;
    }

    &.benefits {
        svg {
            background-color: $basf-blue;
        }
    }

    &.learning-development {
        svg {
            background-color: $basf-green;
        }
    }

    &.work-environment {
        svg {
            background-color: $basf-orange;
        }
    }

    &.compensation {
        svg {
            background-color: $basf-red;
        }
    }
}

.favourites-container{
    grid-column-start: 1;
    grid-column-end: 12;
    padding:2rem 0;
    width: 100%;
    h2{
        display:block;
        text-align:left;
        font-size:3rem;
    }
}

.favourite-disclaimer{
    display:block;
    margin-top:1rem;
    font-size: 1.5rem;
}
.favourites-list {
    margin-top: 2rem;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    li {
        width:33%;
        list-style-type: none;
        padding:2rem;
        border-bottom:1px solid rgba(0,0,0,0.2);
        &:nth-child(odd){
            background:rgba(0,0,0,0.02);
        }
        .favourite-content{
            position:relative;
            display:flex;
            flex-wrap:wrap;
            flex-direction:row;
        }
        .favourite-content__text{
            width:100%;
            text-align:left;
            a{
                display:inline-block;
                margin-top:0.5rem;
                text-decoration:none;
                padding:0.6rem 0.8rem;
                color:#fff;

                &.benefits {
                    background:$basf-blue;
                }
                &.learning-development {
                    background:$basf-green;
                }
                &.work-environment {
                    background:$basf-orange;
                }
                &.compensation {
                    background:$basf-red;
                }
            }
            p{
                line-height:1.5;
            }
            h3{
                color:#000;
                font-size:1.4rem;
                font-weight:bold;
                margin:1rem 0 0.3rem;
            }
        }
        .image{
            height:60px;
            width:60px;
            margin-right:2rem;
            padding:1rem;
        }
        svg.remove{
            max-width:20px;
            height:20px;
            position:absolute;
            right:0;
            background-color:transparent!important;
            fill:rgba(0,0,0,0.5);
            &:hover{
                fill:$basf-red;
            }
        }

        &.benefits {
            svg {
                background-color: $basf-blue;
            }
        }

        &.learning-development {
            svg {
                background-color: $basf-green;
            }
        }

        &.work-environment {
            svg {
                background-color: $basf-orange;
            }
        }

        &.compensation {
            svg {
                background-color: $basf-red;
            }
        }

    }
}

.remove {
    cursor: pointer;
}

.see-favourites{
    cursor:pointer;
    margin-top:2rem;
    background:#ADADAD;
    padding:1rem;
    font-size:1.3rem;
    border:0px solid transparent;
    color:#fff;
    float: left;
    display:flex;
    svg{
        margin-left:1rem;
        width:20px;
        height:20px;
        fill:#fff;
    }
}

.clear-favourites {
    cursor:pointer;
    margin-top:2rem;
    background:#ADADAD;
    padding:1rem;
    font-size:1.3rem;
    border:0px solid transparent;
    color:#fff;
    float: left;
    display:flex;
    svg{
        margin-left:1rem;
        width:20px;
        height:20px;
        fill:#fff;
    }
}













